import React from "react";
import './app.scss';
import Routers from '../../components/routers/routers';


const APP = () => {

  // app create routers
  return (
  
    <Routers></Routers>
  
  );

}

export default APP;
